import React, { useContext, useState } from 'react'
import styles from './LagnateikningarForm.module.scss'
import { LanguageContext } from '../../context'
import { TRANSLATIONS } from '../../misc/translations.js'
import cn from 'classnames'
import { useForm, useFormState } from 'react-hook-form'
import { formsEndpoint } from '../../utils'
import fetch from 'isomorphic-fetch'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import kennitala from 'kennitala'

const LagnateikningarForm = () => {
  const { currentLang: language } = useContext(LanguageContext)
  const t = TRANSLATIONS.hasOwnProperty(language) && TRANSLATIONS[language]
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState()

  const validateSSN = (ssn, t) => {
    ssn = ssn.replace(/\s/g, '')
    const { valid } = kennitala.info(ssn)
    if (!ssn.match(/^\d{6}-?\d{4}$/) || !valid) {
      return t.INVALID_SSN
    }
  }
  const d = new Date()
  const MIN_DATE = d.getFullYear() +  '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2)
  const MAX_DATE = '2027-12-31'

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    mode: 'onTouched',
  })


  const { dirtyFields } = useFormState({
    control
  })

  const required = {
    value: true,
    message: t.emptyInputError
  }

  const setFocus = (id) => {
    document.getElementById(id).focus()
  }

  const onSubmit = data => {
    const structuredData = {
      isAdfRequest: false,
      requestFieldValues: {
        summary: `${data.address} - lagnateikning`,
        description: data.jobDescription,
        customfield_10201: data.name,
        customfield_10198: data.ssn,
        customfield_10183: data.phone_number,
        customfield_10161: data.email,
        customfield_10200: data.address,
        customfield_10191: Number(data.postcode),
        duedate: data.date,
        customfield_10227: data.contractor,
        customfield_10228: data.contractorPhonenumer,
      },
      requestTypeId: 104,
      serviceDeskId: 9,
    }
    
    if (submitting) return
    setSubmitting(true)

    fetch('/api/wp-json/kosmos/v1/jira-connection', {
      method: 'POST',
      body: JSON.stringify(structuredData),
    })
      .then(() => navigate('/takk-fyrir-lagnateikningar'))
      .catch(setError)
      .finally(() => setSubmitting(false))
  }

  const Error = ({ error, message = t.emptyInputError }) => {
    return Boolean(error && error.message) && (
      <p className={cn('pl-1', styles.error)}>{message}</p>
    )
  }

  return (
    <section className='container pb-5 pt-4 mt-3'>
      <form className='row form-styler' onSubmit={handleSubmit(onSubmit)}>
        <div className='col-lg-8 pl-4 ml-1'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('name', { required })}
                  id='name'
                  name='name'
                  type='text'
                />
                <label
                  onClick={() => setFocus('name')}
                  className={cn(
                    {'label--top': dirtyFields.name}
                )}>
                  {t.name}*
                </label>
              </div>
              <Error error={errors.name} />
            </div>
            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('ssn', {
                    required,
                    validate: ssn => validateSSN(ssn, t)
                  })}
                  id='ssn'
                  name='ssn'
                  type='text'
                />
                <label
                  onClick={() => setFocus('ssn')}
                  className={cn(
                    styles.label,
                    {'label--top': dirtyFields.ssn}
                )}>
                  {t.ssn}*
                </label>
              </div>
              <Error error={errors.ssn} message={t.INVALID_SSN} />
            </div>

            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('email', { required })}
                  id='email'
                  name='email'
                  type='text'
                />
                <label
                  onClick={() => setFocus('email')}
                  className={cn(
                    {'label--top': dirtyFields.email}
                )}>
                  {t.email}*
                </label>
              </div>
              <Error error={errors.email} />
            </div>

            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('phone_number', { required })}
                  id='phone_number'
                  name='phone_number'
                  type='number'
                />
                <label
                  onClick={() => setFocus('phone_number')}
                  className={cn(
                    {'label--top': dirtyFields.phone_number}
                )}>
                  {t.phone_number}*
                </label>
              </div>
              <Error error={errors.phone_number} />
            </div>

            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('address', { required })}
                  id='address'
                  name='address'
                  type='text'
                />
                <label
                  onClick={() => setFocus('address')}
                  className={cn(
                    {'label--top': dirtyFields.address}
                )}>
                  {t.address_constructionsite}*
                </label>
              </div>
              <Error error={errors.address} />
            </div>

            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('postcode', { required })}
                  id='postcode'
                  name='postcode'
                  type='text'
                />
                <label
                  onClick={() => setFocus('postcode')}
                  className={cn(
                    {'label--top': dirtyFields.postcode}
                )}>
                  {t.postcode}*
                </label>
              </div>
              <Error error={errors.postcode} />
            </div>

            <div className='col-12'>
              <div className='form-styler form-styler__wrapper'>
                <textarea
                  {...register('jobDescription', { required })}
                  id='jobDescription'
                  name='jobDescription'
                  type='text'
                />
                <label
                  onClick={() => setFocus('jobDescription')}
                  className={cn(
                    {'label--top': dirtyFields.jobDescription}
                )}>
                  {t.jobDescription}*
                </label>
              </div>
              <Error error={errors.jobDescription} />
            </div>
            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('contractor')}
                  id='contractor'
                  name='contractor'
                  type='text'
                />
                <label
                  onClick={() => setFocus('contractor')}
                  className={cn(
                    {'label--top': dirtyFields.contractor}
                )}>
                  {t.contractor}
                </label>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-styler form-styler__wrapper'>
                <input
                  {...register('contractorPhonenumer')}
                  id='contractorPhonenumer'
                  name='contractorPhonenumer'
                  type='number'
                />
                <label
                  onClick={() => setFocus('contractorPhonenumer')}
                  className={cn(
                    {'label--top': dirtyFields.contractorPhonenumer}
                )}>
                  {t.contractorPhonenumer}
                </label>
              </div>
            </div>
            <div className='col-lg-6'>
              <div
                className={cn(
                  { 'dirtyFiled': dirtyFields.date},
                  'form-styler form-styler__wrapper'
                )}>
                <input
                  {...register('date', { required })}
                    id='date'
                    type='date'
                    name='date'
                    max={MAX_DATE}
                    min={MIN_DATE}
                  />
                  <label
                    onClick={() => setFocus('date')}
                    className={cn(
                      styles.label,
                      {'label--top': dirtyFields.date}
                  )}>
                  {t.constructionStart}*
                </label>
              </div>
              <Error error={errors.date} />
            </div>
          </div>
        </div>

        <div className='col-12 pl-4 ml-1'>
          {submitting ? (
            <button
              id='edit-submit'
              name='op'
              aria-label='submit'
              type='submit'
              className={cn(
              'button-pink button-pink--smaller',
              )}>
                <FontAwesomeIcon icon={faSpinner} spin />
            </button>
          ) : (
            <button
              id='edit-submit'
              name='op'
              aria-label='submit'
              type='submit'
              className={cn(
              'button-pink button-pink--smaller',
              )}>
                {t.sendIn}
            </button>
          )}
        </div>
      </form>
    </section>
  )
}

export default LagnateikningarForm
